@import "../../_common.scss";

.form {
    transition: 0.5s opacity;
    text-align: left;

    button {
        width: 100%;
    }
}

.formSending {
    opacity: 0.5;
    pointer-events: none;
}

.formSent {
    opacity: 0;
    animation: formSentAnim 0.5s forwards;
}

@keyframes formSentAnim {
    from {
        visibility: initial;
    }
    to {
        visibility: hidden;
    }
}

.validationField {
    line-height: 1;
    min-height: 0.75rem;
}

.messageSuccess {
    position: absolute;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    animation: messageSuccessAnim 1s forwards;
}

@keyframes messageSuccessAnim {
    from {
        opacity: 0;
    }
    to {
        opacity: 1;
    }
}

.messageSuccessIcon {
    width: 3rem;
    height: 3rem;
}
