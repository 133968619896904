html {
  scroll-behavior: smooth;
}

html.modal-open {
  overflow: hidden;
}

input,
textarea,
button,
select,
a {
  -webkit-tap-highlight-color: rgba(0, 0, 0, 0);
}

button.button.is-ghost {
  height: unset;
  padding: 0;
  vertical-align: unset;

  &:focus {
    border-color: transparent;
    box-shadow: none;
  }
}

.link {
  text-decoration: underline !important;
  text-decoration-style: dotted !important;
  text-underline-position: from-font;

  &:hover {
    color: #363636 !important;
  }
}