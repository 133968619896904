.container {
    position: fixed;
    border-top: 1px solid rgba(255, 255, 255, 0.25);
    z-index: 10;
    left: 0;
    right: 0;
    bottom: 0;

    button:global(.is-ghost) {
        color: white;
        font-weight: bold;
        text-decoration: underline;
        text-decoration-style: dotted;
        text-underline-position: from-font;

        &:hover {
            color: rgb(180, 180, 180);
        }
    }

    strong {
        color: white;
    }
}