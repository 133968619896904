$primary: #0075ff;
$secondary: #242424;

@mixin tiny {
    @media screen and (max-width: 400px) {
        @content;
    }
}

@mixin small {
    @media screen and (max-width: 500px) {
        @content;
    }
}

.ctaButton {
    color: white;
    font-weight: 500;
    vertical-align: top;
    background-color: $primary;
    font-size: 1.1rem;
    padding: 0.6em 1.8em;
    border: 0;
    border-radius: 0;
    box-shadow: none;
    cursor: pointer;

    &.withShadow {
        box-shadow: 0 0.5rem 1.5rem rgba($primary, 0.25);
    }

    &:focus {
        outline: 0;
    }

    &:active {
        padding: calc(0.6em + 1px) 1.8em calc(0.6em - 1px) 1.8em;
        background-color: darken($primary, 5%);
    }

    &.wide {
        width: 100%;
    }

    &.inverted {
        background-color: white;
        color: $primary;

        &:active {
            background-color: lighten($primary, 47%);
        }
    }
}
