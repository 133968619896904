@import "bulma/sass/utilities/_all.sass";
@import "_common.scss";

.logoHeader {
  width: 80px;
  height: 24px;
  background: center / contain no-repeat url("./assets/images/logo.svg");
}

.flex {
  display: flex !important;
}

.navbarMenu {
  display: flex;
  flex: 1;
  justify-content: flex-end;
}

.navbar {
  transition: background-color 0.2s, box-shadow 0.2s;
}

.navbarScrolled {
  background-color: white !important;
  box-shadow: 0px 0px 15px 0px rgba(0, 0, 0, 0.05);
}

.primary {
  color: $primary;
}

.tryItOut {
  margin-bottom: 8em;

  @include mobile {
    margin-bottom: 0em;
  }
}

.hero {
  background: radial-gradient(66.18% 284.05% at 31.01% 63.22%, #fbfbfb 0%, #f5f5f5 100%);
  position: relative;
}

.heroBody {
  background-image: url("./assets/images/spectrum-bg.png");
  background-repeat: repeat-x;
  background-position: bottom;
  padding: 12rem 1.5rem;
  padding-bottom: 0;
  overflow: hidden;

  @include mobile {
    padding-top: 6rem;
  }

  button {
    border-radius: 0.8rem 0;
  }
}

.heroMask {
  background-image: url("./assets/images/hero-banner-mask.svg");
  background-repeat: no-repeat;
  background-position: bottom 0 left 0;
  background-size: 100% 100%;
  width: 100%;
  position: absolute;
  left: 0;
  right: 0;
  bottom: -1px;

  @include mobile {
    height: 1.5rem;
  }

  @include tablet {
    height: 2rem;
  }

  @include desktop {
    height: 3rem;
  }
}

.heroBodyContainer {
  @include mobile {
    min-height: 30rem;
    text-align: center;
  }
}

.heroTextTitle {
  font-family: "Roboto Slab";
  font-weight: normal;
  line-height: 130%;
  @include mobile {
    margin: 0 auto;
    width: 90%;
    max-width: 30rem;
  }
}

.heroTextSubtitle {
  line-height: 1.5;

  @include mobile {
    margin: 0 auto;
    width: 90%;
    max-width: 30rem;
  }
}

.heroine {
  pointer-events: none;
  margin-bottom: 1rem;

  @include widescreen {
    max-width: 210px;
  }

  @include tablet {
    max-width: 210px;
  }

  @include mobile {
    max-width: 180px;
    position: relative;
    right: unset;
    margin: 0 auto;
    margin-bottom: -2rem;
  }

  canvas {
    display: block;
  }
}

.sectionSpacing {
  margin-top: 4rem;
  margin-bottom: 4rem;
}

.demoSection {
  padding: 0;

  @include mobile {
    padding-left: 0;
    padding-right: 0;
  }

  .demo {
    border-radius: 2rem;
    overflow: hidden;
    transform: translate3d(0, 0, 0);
    background-color: white;
    box-shadow: 0px 0px 25px 0px rgba(0, 0, 0, 0.1);

    @include mobile {
      border-radius: 0;
    }
  }
}

.explanation {
  .mainSubheading {
    font-family: "Roboto Slab";
    border-radius: 1rem;
    line-height: 190% !important;
    border: 2px solid lighten($primary, 45%);
    background-color: lighten($primary, 49%);
    box-shadow: rgba($primary, 0.1) 0px 10px 10px -10px;
    margin-top: 3rem;

    @include mobile() {
      margin-top: 0;
      border: none;
      box-shadow: none;
      background-color: transparent;

      &:global(.title) {
        font-size: 1.75rem;
        margin-bottom: 2rem;
      }
    }
  }

  .textBlock {
    line-height: 180%;
  }
}

.reverseColumns {
  display: flex;
  @include mobile {
    flex-direction: column-reverse;
  }
}

.explainFileTranscription {
  max-width: 28rem;
}

:global(#file-transcription-img) > g {
  transition: 0.2s all cubic-bezier(0.34, 1.56, 0.64, 1) 0.05s;
  filter: drop-shadow(0 0.25rem 1.5rem rgba(0, 0, 0, 0.15));

  &:nth-of-type(1) {
    transform-origin: 20% 50%;
  }

  &:nth-of-type(2) {
    transform-origin: 50% 50%;
  }

  &:nth-of-type(3) {
    transform-origin: 80% 50%;
  }

  &:hover {
    transform: translateY(-2.5rem);
    filter: drop-shadow(0 1rem 1.5rem rgba(0, 0, 0, 0.15));
  }
}

.videoContainer {
  margin: 0 auto;
  max-width: 28rem;
  overflow: hidden;
  line-height: 0;
  border-radius: 0.5rem;
  border: 1px solid rgba(0, 0, 0, 0.15);
  box-shadow: 0 0.25rem 2rem rgba(0, 0, 0, 0.2);
}

.gettingStarted {
  margin-top: 6rem;
  margin-bottom: 6rem;

  .freeMinutes {
    font-size: 0.9rem;
  }
}

.explanation {
  :global(.title) {
    font-weight: 500;
    font-size: 2.3rem;
    margin-bottom: 6rem;
    line-height: 3.4rem;
  }

  strong {
    color: inherit;
  }
}

.titleHighlight {
  white-space: nowrap;
  display: inline-block;
  position: relative;
  z-index: 0;
}

.titleHighlight:before {
  content: "";
  background: url("assets/images/text-highlight.png") no-repeat center center;
  background-size: 100% 3.2rem;
  width: 106%;
  height: 100%;
  display: block;
  position: absolute;
  z-index: -1;
  transform: translate(-4%, 0);

  @include mobile {
    background-size: 100% 2.6rem;
  }
}

.shortHighlight {
  white-space: nowrap;
  display: inline-block;
  position: relative;
  z-index: 0;
}

.shortHighlight:before {
  content: "";
  background: url("assets/images/text-highlight-short.png") no-repeat center center;
  background-size: 100% 60px;
  width: 110%;
  height: 100%;
  display: block;
  position: absolute;
  z-index: -1;
  transform: translate(-4%, 0);
}

.listItem {
  list-style-type: square;
}

.signUp {
  background-color: #f8f8f8;
}

.pricingSection {
  padding-top: 6rem;
  padding-bottom: 4rem;
  margin-top: 8rem;
  background: radial-gradient(77.2% 184.1% at 96.15% 94.81%, #f8f8f8 0%, #f8f8f8 100%);

  .freeCredits {
    line-height: 140%;
  }

  @include mobile {
    background: none;
    margin-top: 0;
    padding-top: 3rem;
    padding-bottom: 0;
  }

  .pricingColumns {
    @include tablet {
      --columnGap: 0.75rem !important;
    }

    @include desktop {
      --columnGap: 2rem !important;
    }
  }
}

.pricingBox {
  border-radius: 1rem;
  border: 1px solid lighten($primary, 40%);
  box-shadow: 0 0.25rem 1.5rem rgba(black, 0.04);
  background-color: white;
  background-image: url("./assets/images/pricing-waves.svg");
  background-repeat: no-repeat;
  background-size: 222% auto;

  @include mobile {
    margin: 2rem auto;
    max-width: 25rem;
  }

  &.left {
    background-position: 0px 101%;
  }

  &.right {
    background-position: 87% 101%;
  }

  .priceHolder {
    display: inline-block;
    border-radius: 1rem;
    background-color: rgba(255, 255, 255, 0.8);
    backdrop-filter: blur(4px);
  }

  .description {
    @include tablet {
      min-height: 8rem;
    }
  }

  ul.featureList {
    list-style-type: square;
    list-style-position: inside;
    margin-left: -0.5rem;

    @include tablet {
      text-align: center;
      min-height: 8rem;
    }

    li::marker {
      color: $primary;
    }
  }
}

.footer {
  background-color: $secondary;
  flex-shrink: 0;

  a {
    color: white;

    &:hover {
      text-decoration: underline;
    }
  }
}

.footerLayout {
  @include mobile {
    display: flex;
    flex-direction: column;
  }
}

.logoFooterImage {
  display: inline-block !important;
  width: 80px;
  height: 24px;
  background: center / contain no-repeat url("./assets/images/logo.svg");
  filter: invert(1);
}

.policies {
  margin-bottom: 2rem;
}

.copyrightNotice {
  opacity: 0.5;
}

.footerLogo {
  @include mobile {
    order: 0;
  }
}

.footerLegal {
  font-weight: 100;

  @include mobile {
    margin-top: 2rem;
    order: 2;
  }
}

.footerContact {
  @include mobile {
    order: 1;
  }
}
