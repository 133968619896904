@import url("https://fonts.googleapis.com/css2?family=Jost:wght@400;500;600&subset=latin");
@import url('https://fonts.googleapis.com/css2?family=Roboto+Slab:wght@500&display=swap&subset=latin');
@import "bulma/sass/utilities/initial-variables";

// Update blue
$primary: #0075ff;
$blue: #0075ff;

$tooltip-arrow-size: 12px;
$tooltip-font-size: 0.9rem;
$tooltip-radius: $radius-large;
$tooltip-background-color: #edcf56;
$tooltip-color: #4a4a4a;

// Add a serif family
$family-primary: Jost, $family-sans-serif;

@media screen and (min-width: 769px) {
    .modal-content.large {
        width: 70% !important;
        max-width: 1024px;
    }
}

@import "bulma";
@import "@creativebulma/bulma-tooltip";


@include tablet {
    .container {
        padding-left: 2rem;
        padding-right: 2rem;
    }
}

// NOTE: seems to be required for modal-fx to work in prod mode
.modal {
    display: flex;
}
