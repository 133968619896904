@import "../../common";

.container {
	text-align: center;

	.dot {
		display: inline-block;
		width: 10px;
		height: 10px;
		border-radius: 50%;
		margin-right: 6px;
		background: lighten($primary, 20%);
		animation: wave 1.5s ease-in-out infinite;

		&:nth-child(2) {
			animation-delay: 0.5s;
		}

		&:nth-child(3) {
			animation-delay: 1s;
		}
	}

	@keyframes wave {
		0%,
		33%,
		100% {
			transform: initial;
		}

		15% {
			transform: translateY(-15px);
		}
	}
}
