.button {
    background-color: transparent !important;
}

.buttonLayout {
    width: 100%;
    display: flex;
    align-items: center;

    span {
        flex: 1;
    }
}

.trigger {
    min-width: 7rem;
}

.dropDownArrow {
    margin-right: 0;
    margin-left: 0.3rem;
}

.languageItem {
    display: flex;
    align-items: center;
}

.flag {
    margin-right: 8px;
    width: 12px;
}

.contentContainer {
    min-width: initial !important;
}