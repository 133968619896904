@import '../../common';
@import 'bulma/sass/utilities/initial-variables';

.card {
    width: 100%;
    padding: 1rem;
    background-color: #F8F8F8;
    display: grid;
    grid-template-rows: auto auto 1fr;
    gap: 1rem 0rem;
    transition: background-color 0.5s, transform 0.1s;
    border-radius: 1rem;
}

.active {
    &.card {
        background-color: #c0e6ff;
    }

    .illustration {
        background-color: white;
        canvas {
            transform: scale(1.05);
        }
    }
}

.card > * {
    pointer-events: none;
}

.heading {
    position: relative;
    align-self: flex-end;
    width: 100%;
    display: flex;
    grid-column: 1;
    align-items: center;

    .stepNo {
        position: absolute;
        background-color: #4a4a4a;
        color: white;
        font-size: 1.0rem;
        width: 1.5rem;
        height: 1.5rem;
        border-radius: 50%;
        text-align: center;
        display: inline-flex;
        align-items: center;
        justify-content: center;;
    }

    .title {
        flex: 1;
        text-align: center;
        font-weight: bold;
    }
}

.illustration {
    grid-column: 1;
    margin-bottom: 1rem;
    border-radius: 50%;
    background-color: #EEEEEE;
    transition: 0.25s background-color;
    align-self: center;
    display: block;
    margin: 0 auto;
    overflow: hidden;
    transform: translate3d(0, 0, 0);
    max-width: 230px;

    canvas {
        transition: 0.4s transform ease-out;
        width: 100%;
        display: block;
    }
}

.description {
    grid-column: 1;
    font-size: 1.25rem;
    width: 100%;
    max-width: 300px;
    margin: 0 auto;
    margin-top: 1rem;
    text-align: center;
}

@media only screen and (min-width: 500px) and (max-width: $tablet - 1) {
    .card {
        grid-template-columns: repeat(2, 1fr);
        gap: 0rem 2rem;
    }
    .heading {
        grid-column: 2;

        .stepNo {
            position: initial;
        }

        .title {
            display: inline-block;
            text-align: left;
            margin-left: 1rem;
        }
    }

    .description {
        grid-column: 2;
        width: 100%;
        text-align: left;
    }

    .illustration {
        width: 90%;
        grid-column: 1;
        grid-row: 1 / 3;
    }
}
