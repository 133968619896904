@import "../../_common.scss";

.modal {
    z-index: 999 !important;
}

.textContainer {
    height: 320px;
    padding: 0.25rem;
    overflow-y: scroll;
}